
import {defineComponent, onMounted, onUnmounted, reactive, ref, toRefs} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import APIClient from "@/service/APIClient";
import {numberFormatter} from "@/service/NumberFormatter";

const productIcon = require('@/assets/img/product.png');
const apiUrl = window.API_URL;

const api = new APIClient();


export default defineComponent({
  name: 'Invoice',
  components: {
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const state = reactive({
      internalId: null as string|null,
      apiKey: null as string|null,
      currency: null as string|null,
      startDeal: false as boolean,
      multiplier: 1 as number,
      paymentOption: null as string|null,
      lang: 'ru' as string|null,
      items: [
        {sum: 1},
        {sum: 1.5},
        {sum: 2},
        {sum: 2.5},
        {sum: 3},
        {sum: 3.5},
      ]
    });

    const pay = async (item: any) => {
      const url = await api.createInvoice({
        "internalId": "invoice-" + state.internalId,
        "paymentOption": state.paymentOption,
        "type": "in",
        "userId": "",
        "amount": (item.sum * state.multiplier).toString(),
        "currency": state.currency,
        "notificationUrl": apiUrl + '/webhook',
        "successUrl": "http://success",
        "cancelUrl": "http://fail",
        "notificationToken": "test_token",
        "strictlySingleTransfer": true,
        "startDeal": state.startDeal
      }, state.apiKey!);

      window.location.href = url + '&lang=' + state.lang
    }

    onMounted(() => {
        state.apiKey = route.query.apiKey as string
        state.currency = route.query.currency as string
        state.paymentOption = route.query.option as string
        state.internalId = route.query.internalId as string
        state.startDeal = (route.query.startDeal as string) === '1'
        state.multiplier = parseInt(route.query.multiplier as string)
        state.lang = route.query.lang as string
    })

    onUnmounted(() => {})

    return {
      ...toRefs(state),
      numberFormatter,
      productIcon,
      pay
    }
  }
})
