import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "container-sm mt-5",
  style: {"max-width":"800px"}
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-4" }
const _hoisted_4 = { class: "d-flex flex-column justify-content-center align-items-center m-3" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: require('@/assets/img/product.png'),
              class: "p-5",
              style: {"width":"100%"}
            }, null, 8, _hoisted_5),
            _createElementVNode("span", null, _toDisplayString(_ctx.numberFormatter.formatNumber(item.sum * _ctx.multiplier)) + " " + _toDisplayString(_ctx.currency), 1),
            _createElementVNode("button", {
              onClick: ($event: any) => (_ctx.pay(item)),
              type: "button",
              class: "btn btn-primary w-100"
            }, "Buy", 8, _hoisted_6)
          ])
        ]))
      }), 256))
    ])
  ]))
}