import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = { class: "subtext text-left" }
const _hoisted_4 = { class: "invoice-input-group" }
const _hoisted_5 = { class: "input-group" }
const _hoisted_6 = { class: "input-group-prepend" }
const _hoisted_7 = { class: "currency" }
const _hoisted_8 = ["value"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "d-flex justify-content-between flex-column"
}
const _hoisted_11 = { class: "req-waiting" }
const _hoisted_12 = { class: "msg" }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["href"]
const _hoisted_15 = {
  key: 1,
  class: "d-flex flex-column"
}
const _hoisted_16 = { class: "d-flex justify-content-between" }
const _hoisted_17 = { class: "subtext text-left" }
const _hoisted_18 = { class: "invoice-input-group mb-1" }
const _hoisted_19 = { class: "input-group" }
const _hoisted_20 = { class: "input-group-prepend" }
const _hoisted_21 = ["src", "alt"]
const _hoisted_22 = ["value"]
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 0,
  class: "d-flex justify-content-center mb-4"
}
const _hoisted_25 = { class: "text-left" }
const _hoisted_26 = { class: "subtext" }
const _hoisted_27 = { class: "disclaimer mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('invoice.tooltip.transfer')), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["subtext copied-text", {show: _ctx.copyHintTimeout['amount']}])
        }, _toDisplayString(_ctx.$t('invoice.tooltip.copied')), 3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.sum.currency), 1)
          ]),
          _createElementVNode("input", {
            type: "text",
            class: "form-control invoice-input",
            value: _ctx.numberFormatter.formatNumber(_ctx.sum.amount, 2),
            readonly: ""
          }, null, 8, _hoisted_8),
          _createElementVNode("button", {
            class: "btn btn-copy",
            type: "button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.copy(_ctx.$el, _ctx.sum.amount, 'amount')), ["prevent"]))
          }, [
            _createElementVNode("img", {
              src: require('@/assets/img/copyIcon.svg'),
              alt: "copy"
            }, null, 8, _hoisted_9)
          ])
        ])
      ]),
      (_ctx.deal.paymentOption === 'SBP_QR')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('invoice.make-transfer-qr-text')), 1)
            ]),
            _createElementVNode("img", {
              class: "qr-code",
              src: `${_ctx.apiUrl}/api/public/invoices/${_ctx.deal.id}/qr-code`
            }, null, 8, _hoisted_13),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('invoice.or-text')), 1),
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.deal.requisites.requisites
            }, _toDisplayString(_ctx.$t('invoice.continue-with-link-text')), 9, _hoisted_14)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('invoice.tooltip.to', {method: _ctx.$t('methods.' + _ctx.deal.paymentMethod)})), 1),
              _createElementVNode("div", {
                class: _normalizeClass(["subtext copied-text", {show: _ctx.copyHintTimeout['req']}])
              }, _toDisplayString(_ctx.$t('invoice.tooltip.copied')), 3)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("img", {
                    class: "method",
                    src: _ctx.paymentMethodsHelper.getLogo(_ctx.deal.paymentMethod),
                    alt: _ctx.$t('methods.' + _ctx.deal.paymentMethod)
                  }, null, 8, _hoisted_21)
                ]),
                _createElementVNode("input", {
                  type: "text",
                  class: "form-control invoice-input",
                  value: _ctx.formatCC(_ctx.deal.requisites.requisites),
                  readonly: ""
                }, null, 8, _hoisted_22),
                _createElementVNode("button", {
                  class: "btn btn-copy",
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.copy(_ctx.$el, _ctx.deal.requisites.requisites, 'req')), ["prevent"]))
                }, [
                  _createElementVNode("img", {
                    src: require('@/assets/img/copyIcon.svg'),
                    alt: "copy"
                  }, null, 8, _hoisted_23)
                ])
              ])
            ]),
            (_ctx.deal.requisites.holder)
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('invoice.tooltip.holder')) + ":", 1),
                    _createTextVNode(),
                    _createElementVNode("span", null, _toDisplayString(_ctx.deal.requisites.holder), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('invoice.transfer-attention')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('invoice.transfer-disclaimer')), 1)
    ])
  ]))
}