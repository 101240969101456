<template>
  <router-view :key="$route.fullPath"/>
</template>

<script>

import axios from "axios";
import APIClient from "@/service/APIClient";

const api = new APIClient();

export default {
  name: "App",
  computed: {},
  methods: {
    resolveApiUrl: async function () {
      let promises = [];
      let resolved = false;
      for (let i = 0; i <= 3; i++) {
        const suffix = i === 0 ? '' : i.toString();
        let baseURL = window.API_URL.replace('api.', `api${suffix}.`);
        promises.push(api.ping(baseURL).then(() => {
          if (resolved === false) {
            resolved = true;
            axios.defaults.baseURL = baseURL
            console.log('api url: ' + axios.defaults.baseURL)
          }
        }));
      }

      try {
        await Promise.any(promises);
      } catch (e) {
        console.log('api url failed')
        axios.defaults.baseURL = window.API_URL;
      }

    },
  },
  async created() {
    //await this.resolveApiUrl();
  }
}
</script>

