import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import "./assets/css/bootstrap.min.css"
import "./assets/css/styles.css"


axios.defaults.baseURL = window.API_URL;
axios.defaults.headers.common = {
  'Content-Type': 'application/json;charset=UTF-8',
}

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get('lang');

const i18n = createI18n({
  locale: lang ? (['ru', 'en'].includes(lang.toLowerCase()) ? lang.toLowerCase() : 'ru') : 'ru',
  fallbackLocale: 'en',
  messages: {
    en: require('@/i18n/messages.en.json'),
    ru: require('@/i18n/messages.ru.json'),
  }
})

createApp(App).use(i18n).use(VueAxios, axios).use(router).mount('#app')
