
import {toRefs, reactive, defineComponent, PropType} from 'vue'
import DealDTO from "@/models/DealDTO";
import MoneyDTO from "@/models/MoneyDTO";
import {paymentMethodsHelper} from "@/service/PaymentMethodsHelper"
import {numberFormatter} from "@/service/NumberFormatter"
const apiUrl = window.API_URL;

const fallbackCopyTextToClipboard = (obj: any, text: string) => {
  let textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    document.body.removeChild(textArea);
    return successful;

  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
  return 0
}

const copyTextToClipboard = (obj: any, text: string) => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(obj, text);
  }
  navigator.clipboard.writeText(text).then(function() {
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export default defineComponent({
  props: {
    deal: {
      type: Object as PropType<DealDTO>,
      default: false
    },
    sum: {
      type: Object as PropType<MoneyDTO>,
      default: false
    }
  },
  setup(props) {

    const state = reactive({
      copyHintTimeout: {'amount': null, 'req': null} as any,
    });

    const copy = (obj: any, value: string, hint: string) => {
      copyTextToClipboard(obj, value);
      if (state.copyHintTimeout[hint]) {
        clearTimeout(state.copyHintTimeout[hint])
      }
      state.copyHintTimeout[hint] = setTimeout(() => {
        state.copyHintTimeout[hint] = null;
      }, 1000);
    }

    const formatCC = (value: string): string => {
      return value
    }

    return {
      ...toRefs(state),
      paymentMethodsHelper,
      numberFormatter,
      formatCC,
      copy,
      apiUrl
    }
  }
})
